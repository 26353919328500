

























































import Component from 'vue-class-component';
import Vue from 'vue';
import {email, required, minLength} from 'vuelidate/lib/validators'
import RsInput from '@/shared/components/fields/input.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import {AUTH_SERVICE} from '@/modules/onboarding/services/auth.service';
import {LOCALSTORAGE} from '@/shared/constants/LOCALSTORAGE';

interface FormSignup {
  email: string;
  password: string;
}

@Component({
  name: 'rs-onboarding-form-signup',
  components: {RsButton, RsInput},
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
    },
  }
})
export default class OnboardingFormSignup extends Vue {
  form: FormSignup = {
    email: '',
    password: ''
  }

  sending = false;
  error = '';

  submit() {
    this.error = '';
    this.sending = true;
    AUTH_SERVICE.signup(this.form.email, this.form.password)
      .then(() => {
        this.$track.sendEvent('SignUp')
        localStorage.setItem(LOCALSTORAGE.SIGNUP, 'true');
        return AUTH_SERVICE.login(this.form.email, this.form.password)
      })
      .catch((err) => {
        this.error = err.description;
      })
      .finally(() => {
        this.sending = false;
      });
  }
}
